import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { USER_IP_ADDRESS } from '@core/constants/local-storage-keys.constants';
import { environment } from '@env';

@Injectable({
  providedIn: 'root'
})
export class Stage1JourneysService {
  getIpAddress = localStorage.getItem(USER_IP_ADDRESS) || "null";
  headerOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      // 'IPAddress' : this.getIpAddress
    }),
  };

  constructor(private httpClient: HttpClient) { }

  getJouneysList(projectId: any) {
    return this.httpClient.get(environment.getAllJourneys + "?proj_id="+ projectId, this.headerOptions)
  }

  createJourneys(data: any) {
    return this.httpClient.post(environment.createJourneys ,data, this.headerOptions)
  }

  getSelectedJourneys(projectId: any) {
    return this.httpClient.get(environment.getSelectedJourneys + "?suj_project_id="+ projectId, this.headerOptions)
  }

  deleteStep(deleteid: any) {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'IPAddress' : this.getIpAddress
      }),
      body: deleteid
    };
    return this.httpClient.delete(environment.deleteStep, options)

  }

  deleteJourney(data: any) {
    return this.httpClient.delete(environment.deleteJourney + "/"+data, this.headerOptions)
  }

  getScenaioList(pageflowId: any, journeyId: any) {
    return this.httpClient.get(environment.getScenario +"?pageFlowId=" + pageflowId+ "&journeyId="+ journeyId, this.headerOptions)
  }

  saveJourney(data: any) {
    return this.httpClient.put(environment.saveStage1 ,data, this.headerOptions)
  }

  createJourneyWithoutTemplateApi(data: any) {
    return this.httpClient.post(environment.createJourneyWithoutTemplate ,data)
  }

  createNewScenario(data: any) {
    return this.httpClient.post(environment.createNewScenario ,data)
  }

  updateBackground(data: any) {
    return this.httpClient.put(environment.createNewScenario ,data)
  }

  updateExecutionOrder(data: any) {
    return this.httpClient.put(environment.createNewScenario ,data)
  }

  updateScenario(data: any) {
    return this.httpClient.put(environment.updateScenario ,data)
  }

  getJourneyDetails(projectType: any, projectId: any) {
    return this.httpClient.get(environment.sitemapPageAPI + "?sp_project_type=" + projectType + "&proj_id=" + projectId, this.headerOptions);
  }
 
  createFeatureFile(data:any){
    return this.httpClient.post(environment.createFeatureFile,data, this.headerOptions)
  }
}
