import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, OnInit } from '@angular/core';
import { USER_IP_ADDRESS } from '@core/constants/local-storage-keys.constants';
import { environment } from '@env';

@Injectable({
  providedIn: 'root'
})
export class UsermanagementService {
  
  getIpAddress = localStorage.getItem(USER_IP_ADDRESS) || "null";
  headerOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      'IPAddress' : this.getIpAddress
    }),
  };
  constructor(private httpClient: HttpClient) { 
  }

  getUsers(projectId: any, customerId: any){
      return this.httpClient.get(environment.userAPI + "?sp_id=" + projectId + "&sc_id=" + customerId, this.headerOptions);

  }

  createUser(data: any) {
    return this.httpClient.post(environment.createUser, data , this.headerOptions)
  }
}
  
