import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '@env';
import { Injectable, OnInit } from '@angular/core';
import { AuthService } from '@auth/auth.service';
import { PROJECT_CODE_KEY, USER_IP_ADDRESS } from '@core/constants/local-storage-keys.constants';
import { SettingsModule } from '../settings.module';

@Injectable({
  providedIn: 'root'
})
export class CustomerService {
  projectCode: string | any;
  getIpAddress = localStorage.getItem(USER_IP_ADDRESS) || "null";
  headerOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      'IPAddress' : this.getIpAddress
    }),
  };
  constructor(private httpClient: HttpClient) { 
  }
  
  uploadLogo(data: any) {
    const headers= new HttpHeaders().set('content-type', 'multipart/form-data')    
    return this.httpClient.post(environment.settingsUploadImage, data, this.headerOptions);
  }

  updateCustomer(updatedCustomer: any){
    return this.httpClient.post(environment.customerAPI + '/update' , updatedCustomer,this.headerOptions);
  }

  getCustomerById(customerId: any){
    return this.httpClient.get(environment.customerAPI + '/' + customerId, this.headerOptions);

  }
}
