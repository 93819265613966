import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { USER_IP_ADDRESS } from '@core/constants/local-storage-keys.constants';
import { environment } from '@env';

@Injectable({
  providedIn: 'root'
})
export class Stage2FeatureFileService {
  getIpAddress = localStorage.getItem(USER_IP_ADDRESS) || "null";
  headerOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      // 'IPAddress' : this.getIpAddress
    }),
  };

  constructor(private httpClient: HttpClient) { }

  getJouneysList(projectId: any) {
    return this.httpClient.get(environment.deleteJourney + "?suj_project_id="+ projectId, this.headerOptions)
  }

  createFeatureFile(data:any){
    return this.httpClient.post(environment.createFeatureFile,data, this.headerOptions)
  }
  
  updateScenarioName(data:any){
    return this.httpClient.put(environment.createNewScenario,data, this.headerOptions)
  }

  addStep(data: any) {
    return this.httpClient.post(environment.addStep,data, this.headerOptions)

  }

  updateStep(data: any) {
    return this.httpClient.put(environment.addStep,data, this.headerOptions)

  }

  deleteStep(data: any) {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'IPAddress' : this.getIpAddress
      }),
      body: data
    };
    return this.httpClient.delete(environment.addStep, options)

  }

  createJourneyTag(data: any) {
    return this.httpClient.post(environment.createJourneyTag,data, this.headerOptions)

  }

  deleteJourneyTags(data: any) {
    return this.httpClient.put(environment.createJourneyTag , data, this.headerOptions)
  }

  createScenarioTag(data: any) {
    return this.httpClient.post(environment.scenarioTag,data, this.headerOptions)
  }

  deleteScenarioTag(data: any) {
    return this.httpClient.put(environment.scenarioTag,data, this.headerOptions)
  }

  deleteFeature(data: any) {
    return this.httpClient.delete(environment.deleteFeature + "/"+data, this.headerOptions)
  }

  updateExecOrder(data: any) {
    return this.httpClient.put(environment.addStep,data, this.headerOptions)

  }

  getStepSuggestions(data: any) {
    return this.httpClient.get(environment.stepSuggestions + "?sd_step_name="+ data +"&" +"limit="+ 10, this.headerOptions)
  }
}
